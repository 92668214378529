// firebase/index
export const firebaseConfig = {
  apiKey: "AIzaSyB4WYQc-F5Pbdtd5PoB7VLKABssP0PYzls",
  authDomain: "expopresentes-6662b.firebaseapp.com",
  databaseURL: "https://expopresentes-6662b.firebaseio.com",
  projectId: "expopresentes-6662b",
  storageBucket: "expopresentes-6662b.appspot.com",
  messagingSenderId: "15851286980",
  appId: "1:15851286980:web:a17a316ba7dfde49ab3491",
  measurementId: "G-7FH9BQ9KPC"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/expopresentes-6662b.appspot.com/o/'

